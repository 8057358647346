function SVGBackgroundsNoBg({ bgColor }) {
  return (
    <>
      <svg
        className="first-svg"
        viewBox="0 0 200 200"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill={bgColor}
          d="M54.9,-24C60.3,-1.3,46.3,21.6,25.2,37.6C4.1,53.6,-24.1,62.8,-38.6,52.4C-53.2,42,-54,12.1,-45.3,-15.2C-36.6,-42.5,-18.3,-67.2,3.2,-68.3C24.7,-69.3,49.5,-46.7,54.9,-24Z"
          transform="translate(100 100)"
        />
      </svg>

      <svg
        className="second-svg"
        viewBox="0 0 200 200"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill="#ffffff99"
          d="M54.9,-24C60.3,-1.3,46.3,21.6,25.2,37.6C4.1,53.6,-24.1,62.8,-38.6,52.4C-53.2,42,-54,12.1,-45.3,-15.2C-36.6,-42.5,-18.3,-67.2,3.2,-68.3C24.7,-69.3,49.5,-46.7,54.9,-24Z"
          transform="translate(100 100)"
        />
      </svg>
    </>
  );
}

export default SVGBackgroundsNoBg;
