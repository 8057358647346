import ar from "assets/logo/ar.png";
import en from "assets/logo/en.png";
import fa from "assets/logo/fa.png";
import ru from "assets/logo/ru.png";
import tr from "assets/logo/tr.png";

export const ALL_LANGUAGES = {
  fa: {
    image: fa,
    text: "fa",
  },
  tr: {
    image: tr,
    text: "tr",
  },
  ar: {
    image: ar,
    text: "ar",
  },
  en: {
    image: en,
    text: "en",
  },
  ru: {
    image: ru,
    text: "ru",
  },
};
