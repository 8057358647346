import { adultWipe } from "./AdultWipe/constants";
import { antioxidantMakeupRemover } from "./AntioxidantMakeupRemover/constants";
import { babyWetWipe } from "./BabyWetWipe/constants";
import { babyWetWipeSensitive } from "./BabyWetWipeSensitive/constants";
import { bathClean } from "./BathClean/constants";
import { deepNourishingCream } from "./DeepNourishingCream/constants";
import { eyeMakeupRemover } from "./EyeMakeupRemover/constants";
import { faceWash } from "./FaceWash/constants";
import { footCareVaseline } from "./FootCareVaseline/constants";
import { hairMask } from "./HairMask/constants";
import { handBodyLotion } from "./HandBodyLotion/constants";
import { herbalDeodorant } from "./HerbalDeodorant/constants";
import { householdCleansing } from "./HouseholdCleansing/constants";
import { intimateWipe } from "./IntimateWipe/constants";
import { makeUpRemover } from "./MakeUpRemover/constants";
import { micellarCleansingWater } from "./MicellarCleansingWater/constants";
import { perfumeCream } from "./PerfumeCream/constants";
import { q10Flower } from "./Q10Flower/constants";
import { q10fruits } from "./Q10Product/constants";
import { quickPolishRemover } from "./QuickPolishRemover/constants";
import { royalBeauty } from "./RoyalBeauty/constants";
import { vaseline } from "./Vaseline/constants";
import { waterWipesAntibacterial } from "./WaterWipesAntibacterial/constants";
import { wetWipesKissesOnBaby } from "./WetWipesKissesOnBaby/constants";
import { Lipbalm } from "./Lipbalm/constants";

export const ALL_PRODUCTS = {
  "lip-balm": Lipbalm,
  "adult-wipe": adultWipe,
  "antioxidant-make-up-remover": antioxidantMakeupRemover,
  "baby-wet-wipe": babyWetWipe,
  "baby-wet-wipe-sensitive": babyWetWipeSensitive,
  "bath-clean": bathClean,
  "deep-nourishing-cream": deepNourishingCream,
  "eye-make-up-remover": eyeMakeupRemover,
  "face-wash": faceWash,
  "vaseline": vaseline,
  "foot-care-vaseline": footCareVaseline,
  "hair-mask": hairMask,
  "hand-body-lotion": handBodyLotion,
  "herbal-deodorant": herbalDeodorant,
  "household-cleansing": householdCleansing,
  "intimate-wipe": intimateWipe,
  "make-up-remover": makeUpRemover,
  "micellar-cleansing-water": micellarCleansingWater,
  "perfume-cream": perfumeCream,
  "q10-flower": q10Flower,
  "q10-fruit": q10fruits,
  "quick-polish-remover": quickPolishRemover,
  "royal-beauty": royalBeauty,
  "water-wipes-antibacterial": waterWipesAntibacterial,
  "wet-wipes-kisses-on-baby": wetWipesKissesOnBaby
};
