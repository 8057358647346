import object1 from "./1.png";
import object3 from "./3.png";
import object4 from "./4.png";
import object5 from "./5.png";
import object6 from "./6.png";
import object7 from "./7.png";
import object8 from "./8.png";
import object9 from "./9.png";

export const herbalDeodorantObject1 = [
  object1,
  object1,
  object1,
  object1,
  object1,
];
export const herbalDeodorantObject2 = [
  object3,
  object4,
  object3,
  object4,
  object3,
];
export const herbalDeodorantObject3 = [
  object5,
  object6,
  object5,
  object6,
  object5,
];
export const herbalDeodorantObject4 = [
  object8,
  object8,
  object9,
  object7,
  object8,
];
