import fig1 from "./f1.png";
import fig2 from "./f2.png";
import fig3 from "./f3.png";
import fig4 from "./f4.png";
import fig5 from "./f1.png";

import avocado1 from "./a1.png";
import avocado2 from "./a1.png";
import avocado3 from "./a1.png";
import avocado4 from "./a1.png";
import avocado5 from "./a1.png";

import blueberry1 from "./b2.png";
import blueberry2 from "./b1.png";
import blueberry3 from "./b2.png";
import blueberry4 from "./b1.png";
import blueberry5 from "./b2.png";

export const q10FlowerFig = [fig1, fig2, fig3, fig4, fig5];

export const q10FlowerAvocado = [
  avocado1,
  avocado2,
  avocado3,
  avocado4,
  avocado5,
];

export const q10FlowerBlueberry = [
  blueberry1,
  blueberry2,
  blueberry3,
  blueberry4,
  blueberry5,
];
