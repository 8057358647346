import pinkFlower1 from "./1.png";
import pinkFlower2 from "./2.png";
import pinkFlower3 from "./3.png";
import pinkFlower4 from "./4.png";
import pinkFlower5 from "./5.png";

import colorful1 from "./ful1.png";
import colorful2 from "./ful2.png";
import colorful3 from "./ful1.png";
import colorful4 from "./ful2.png";
import colorful5 from "./ful1.png";

import purple1 from "./p1.png";
import purple2 from "./p2.png";
import purple3 from "./p3.png";
import purple4 from "./p4.png";
import purple5 from "./p5.png";

export const q10FlowerPink = [
  pinkFlower1,
  pinkFlower2,
  pinkFlower3,
  pinkFlower4,
  pinkFlower5,
];

export const q10FlowerColorful = [
  colorful1,
  colorful2,
  colorful3,
  colorful4,
  colorful5,
];

export const q10FlowerPurple = [purple1, purple2, purple3, purple4, purple5];
