import object1 from "./1.png";
import object2 from "./2.png";
import object3 from "./3.png";

export const quickPolishRemover1 = [
  object1,
  object1,
  object3,
  object2,
  object3,
];
