import object1 from "./1.png";
import object2 from "./2.png";
import object3 from "./3.png";
import object4 from "./4.png";
import object5 from "./5.png";

export const babyWetWipeSensitiveItem = [
  object3,
  object1,
  object2,
  object5,
  object4,
];
